@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap'); /* Import Garamond from Google Fonts */

.website {
  font-family: 'EB Garamond', Garamond, serif;
}

.navBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navBar.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.navBar.visible {
  transform: translateY(0);
  opacity: 1;
}

.banner {
  color : #333;
  font-size: clamp(20px, 5vw, 24px);
  padding: clamp(15px, 1.5vw, 20px);
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-style: italic;
  text-align: center;
}

.menuButton { 
  color: #333;
  font-size: clamp(20px, 4.5vw, 25px);
  padding: clamp(10px, 0.8vw, 15px);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-left: 1px solid #ccc;
  text-align: center;
}

.banner:hover,
.menuButton:hover {
  color: #ddd;
}

.content {
  margin-top: clamp(110px, 15vh, 120px);
  margin-bottom: 40px;
  padding: 1vw;
  text-align: center;
}

@media screen and (max-width: 1000px) and (max-height: 1000px) {
  .content {
    margin-top: 16.5vh;
  }
}

@media screen and (max-width: 1000px) and (max-height: 900px) {
  .content {
    margin-top: 18.5vh;
  }
}

@media screen and (max-width: 1000px) and (max-height: 750px) {
  .content {
    margin-top: 22.5vh;
  }
}

.photographyPage {
  display: flex;
  flex-wrap: wrap; /* Allows images to wrap onto new rows */
  gap: 20px;       /* Adds space between images */
  justify-content: center; /* Centers the images */
}

.photographyPage img {
  max-width: 90vw;
  max-height: 90vh;
  height: auto;
  object-fit: contain;
  display: block;
}

.filmographyPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.film {
  display: flex; /* Use flexbox to align content */
  flex-direction: row; /* Align text and image in a row */
  gap: 20px; /* Add space between text and image */
  justify-content: left;
  align-items: flex-start;
  padding: 20px;
  max-width: clamp(400px, 70vw, 70vw);
  border: none;
  border-bottom: 1px solid rgb(87, 83, 83);
  position: relative;
}

.film h1 {
  text-transform: uppercase;
}

.filmText {
  display: flex;
  flex-direction: column;
  flex: 1; /* Let text take remaining space */
}

.film img {
  max-width: clamp(300px, 15vw, 15vw); /* Control image size */
  height: auto;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .film {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center align the content */
  }

  .film img {
    max-width: 80%; /* Adjust image size for smaller screens */
  }
}


.filmNavButtons {
  display: flex; /* Align buttons */
  flex-direction: row;
  justify-content: center; /* Center buttons horizontally */
  gap: 15px; /* Add space between buttons */
  margin-top: 15px;
}

.filmNavButtons a {
  display: inline-block;
  width: clamp(100px, 10vw, 400px);
  padding: 10px;
  text-align: center; 
  background-color: rgb(87, 83, 83);
  color: #ffffff; /* Set link color */
  text-decoration: none;
}

.filmNavButtons a:hover {
  text-decoration: underline;
}


.aboutPageContent img {
  max-width: 90vw;
  width: 400px;
  height: auto;
  border: none;
}

@media (min-width: 1000px) {
  .aboutPageContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-inline: clamp(5%, 17%);
    margin-inline: 10%;
    
  }

  .aboutPageContent img {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.contactPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  max-width: 90vw;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
}
.contactPage label {
  display: block;
  margin-top: 10px;
  font-size: clamp(10px, 10vw, 20px);
}

.contactPage input {
  margin-top: 5px;
  max-width: 90vw;
  width: 400px;
  height: 30px;
  border: 1px solid #ccc;
}

.contactPage button {
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  max-width: 90vw;
  width: 400px;
  height: 30px;
  background-color: rgb(87, 83, 83);
  color: #fff;
  font-family: 'EB Garamond', Garamond, serif;
  font-weight: normal;
  font-size: clamp(5px, 5vw, 20px);
}

.contactPage textarea {
  margin-top: 10px;
  height: 150px;
  width: 400px;
  max-width: 90vw;
  border: 1px solid #ccc;
}

.footer {
  color: #333;
  background-color: #ffffff;
  font-size: clamp(12px, 1vw, 15px);
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  z-index: 1000;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.footer.hidden {
  transform: translateY(100%);
  opacity: 0;
}

.footer.visible {
  transform: translateY(0);
  opacity: 1;
}
